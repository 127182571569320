import 'es6-promise/auto'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueResource from "vue-resource"
import 'xe-utils'
import { Button,Cell,CellGroup,NoticeBar,Sidebar,SidebarItem,Tabbar,TabbarItem,Col, Row,
Swipe,SwipeItem,Switch,Lazyload,Collapse, CollapseItem,ImagePreview,Popup,Overlay,Loading, 
Field, Dialog, Uploader, Card, Circle, Tab, Tabs, TreeSelect, Stepper, RadioGroup, Radio} from "vant";
import VXETable from 'vxe-table'
// import VueI18n from 'vue-i18n'
import {i18n, vantLocales} from './lang'
import VueCropper from 'vue-cropper'
import axios from 'axios'
import $ from 'jquery'
Vue.config.productionTip = false;

Vue.use(Button)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Sidebar)
Vue.use(SidebarItem)
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Card);
Vue.use(Circle);
Vue.use(Switch);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Field);
Vue.use(TreeSelect);
Vue.use(Col);
Vue.use(Row);
Vue.use(Stepper);
Vue.use(Lazyload,{
	lazyComponet: true,
})
Vue.use(NoticeBar);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(ImagePreview);
Vue.use(Popup);
Vue.use(VXETable);
Vue.use(VueResource);
Vue.use(VueCropper);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Dialog);
Vue.use(Uploader)

Vue.prototype.$ = $
Vue.prototype.$axios = axios;
let getCookie = function (cookie) {
    let reg = /csrftoken=([\w]+)[;]?/g
    return reg.exec(cookie)[1]
}

axios.interceptors.request.use(
  function(config) {
    // 在post请求前统一添加X-CSRFToken的header信息
    let cookie = document.cookie;
    if(cookie && config.method == 'post'){
      config.headers['X-CSRFToken'] = getCookie(cookie);
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  });
  
console.log('当前的语言是：', i18n.locale)
vantLocales(i18n.locale)
new Vue({
  el: '#app',
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
